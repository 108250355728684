import React from 'react'
import "./PaycodPolicy.scss"


function PaycodPolicy() {
    return (
        <div>
            <div className="policy-page">
                <h1 className="policy-page__title">Privacy Policy</h1>
                <p className="policy-page__content">
                    Jadbery (Eva's Wardrobe, Ooty Road, Valiyangadi, Perinthalmanna, Kerala, India)provides PayCOD | COD Upfront Payment "the App" an online software application "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
                    <h1 className="policy-page__title">Personal Information the App Collects</h1>
                    When you install the App, we are automatically able to access certain types of information from your Shopify account: Shop email, Store language, Orders, Products, Translations.
                    Additionally, we collect the following types of personal information from you once you have installed the App:
                    Information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information;
                    We collect personal information directly from the relevant individual, through your Shopify account;
                    <h1 className="policy-page__title">How Do We Use Your Personal Information?</h1>
                    We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information relating to our products or services. Your personal information is not sold or shared with anyone unless required by law.
                    <h1 className="policy-page__title">Customer information</h1>
                    Your customers' information “Customer Data” is used solely to provide the Service. Our Service provides our customers with a wide variety of tools and features to better manage and optimize their end-users' experience and interaction with the customer's online assets. Certain privacy and data protection laws require that prior to processing personal data, a legal basis for such processing is established. Accordingly, whilst Jadbery | Paycod supports these laws by providing our customers with features and options for how they use our Service - our customer is solely responsible for determining whether and how they wish to use our Service and such features, and to ensure that the appropriate legal basis for the processing of Customer Data have been established, by the customer, prior to having such data be through the Service. Jadbery | Paycod processes Customer Data, and the personal data contained in it, strictly on our customer's behalf, in accordance with their reasonable instructions. Accordingly, to the extent applicable by law, our customer will be deemed the 'data controller' (e.g. under the EU GDPR, UK GDPR and similar laws) or 'business' (under the CCPA or similar laws) of Customer Data; and Jadbery | Paycod will be deemed the 'data processor' or 'service provider' when processing such data.
                    We do not sell or share Customer Data with anyone, unless required by law. If you enable the SMS options on the App, we might share your Customer Data with our SMS providers for the sole purpose of providing the Service.
                    <h1 className="policy-page__title">Sharing Your Personal Information</h1>
                    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                    Your Rights
                    If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                    Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
                    <h1 className="policy-page__title">Data Retention</h1>
                    Your Personal Information is stored securely on our servers while you use our Service. If you uninstall the App from your store, your Personal Information is deleted from our databases after 48h from the uninstall.
                    Other information about your activity on the App is stored securely on our servers for up to 90 days.
                    <h1 className="policy-page__title">Changes</h1>
                    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                    <h1 className="policy-page__title">Contact Us</h1>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us on the contact us page of the App.
                </p>
            </div>

        </div>
    )
}

export default PaycodPolicy